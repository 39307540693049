import { memo, FC, useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ShieldIcon from '@assets/icons/misc/shield-icon.svg';

import { Prompt } from '@components';
import { Icon } from '@eon-home/react-library';

import { useGetDataAnalysisConsent } from '@store/selectors';
import { consentActionIsOlderThan } from '@tools/utils/data-analisys';
import { DataAnalysisConfig } from '@tools/constants';
import { useDataAnalysisConsent } from '@tools/utils';
import { Routes } from '@tools/enums/routes';

import './index.scss';

export const DataConsent: FC = () => {
    const { t } = useTranslation();
    const dataAnalysisConsent = useGetDataAnalysisConsent();
    const updateConsentStatus = useDataAnalysisConsent(dataAnalysisConsent);

    const hasValidationExpired = useMemo(
        () =>
            dataAnalysisConsent?.timestamp
                ? consentActionIsOlderThan(
                      dataAnalysisConsent.timestamp,
                      DataAnalysisConfig.NEXT_TRIGGER_DELAY, // 4 months
                      'months',
                  )
                : false,
        [dataAnalysisConsent?.timestamp],
    );

    const initialPromptState = (): boolean => {
        const savedPromptState = sessionStorage.getItem('promptState');
        const promptClosedStatus = JSON.parse(
            sessionStorage.getItem('promptCloseState') || 'false',
        );

        if (!promptClosedStatus && !hasValidationExpired) return false;

        return savedPromptState !== null
            ? JSON.parse(savedPromptState)
            : !!dataAnalysisConsent?.opting;
    };

    const [isPromptShownState, setIsPromptShownState] =
        useState<boolean>(initialPromptState);

    const { pathname } = useLocation();

    const handleSucces = useCallback(() => {
        setIsPromptShownState(true);
        updateConsentStatus(true);
        sessionStorage.setItem('promptState', 'true');
        sessionStorage.setItem('promptCloseState', 'true');
    }, [updateConsentStatus]);

    const handleCancel = useCallback(() => {
        setIsPromptShownState(true);
        updateConsentStatus(false);
        sessionStorage.setItem('promptState', 'false');
        sessionStorage.setItem('promptCloseState', 'true');
    }, [updateConsentStatus]);

    useEffect(() => {
        // During login, it is crucial to verify if the user's validation period has expired.
        // The expiration duration is specified by DataAnalysisConfig.NEXT_TRIGGER_DELAY.
        if (!dataAnalysisConsent?.opting && hasValidationExpired) {
            sessionStorage.setItem('promptState', 'false');
            sessionStorage.setItem('promptCloseState', 'false');
        }
    }, [dataAnalysisConsent?.opting, hasValidationExpired]);

    const shouldNotRender = useMemo(() => {
        // The popup should never be visible on the MORE_CONSENT page
        if (pathname.includes(Routes.MORE_CONSENT)) return true;

        // User has never set consent (new user or old user who never set CONSENT)
        if (typeof dataAnalysisConsent === 'undefined') return false;

        const promptClosedStatus = sessionStorage.getItem('promptCloseState');
        const storageStage = isPromptShownState !== null && isPromptShownState;
        const closeState =
            promptClosedStatus !== null && JSON.parse(promptClosedStatus);

        if (closeState || storageStage || !hasValidationExpired) return true;

        return dataAnalysisConsent?.opting;
    }, [
        pathname,
        isPromptShownState,
        dataAnalysisConsent,
        hasValidationExpired,
    ]);

    if (shouldNotRender) return null;

    return (
        <Prompt
            title={t('Consent for data analysis')}
            onConfirm={handleSucces}
            onCancel={handleCancel}
            confirmText={t('Accept')}
            cancelText={t('Decline')}
            small={true}
            alignLeft={true}
            className="c-data-consent__prompt"
        >
            <figure className="c-data-consent__icon">
                <Icon src={ShieldIcon} />
            </figure>
            <p>{t('Consent for data analysis modal description')}</p>
        </Prompt>
    );
};

export default memo(DataConsent);
